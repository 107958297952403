// React imports
import React, { ReactElement } from "react";
// Externals imports
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent } from "@mui/material";
import { Supplier } from "@@types/suppliers/supplier.type";

type QuickPepSupplierLocationsDropdownProps = {
  value: string;
  error: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  supplierLocations: Supplier[];
};

const QuickPepSupplierLocationsDropdown = ({
  value,
  error,
  onChange,
  supplierLocations,
}: QuickPepSupplierLocationsDropdownProps): ReactElement => {
  const renderMenuItems = (options: Supplier[]): ReactElement | ReactElement[] => {
    if (!options) {
      return <Dropdown.MenuItem value=""></Dropdown.MenuItem>;
    }
    return options.map((option: Supplier) => (
      <Dropdown.MenuItem
        key={option.id}
        value={option.id.toString()}
      >
        {option.name}
      </Dropdown.MenuItem>
    ));
  };
  return (
    <Dropdown
      disabled={!supplierLocations.length}
      value={value || ""}
      name={"supplierLocation"}
      onChange={onChange}
      error={error}
      label="Point de chute"
      fullWidth
    >
      {renderMenuItems(supplierLocations)}
    </Dropdown>
  );
};

export default QuickPepSupplierLocationsDropdown;
