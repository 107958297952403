// React imports
import React, { useEffect, memo, useMemo, useState, useCallback, Dispatch, SetStateAction } from "react";

// Internal imports
import * as API from "@services";
import { isArrayTruthy } from "@utils/common";
import useAsync from "@hooks/useAsync";
import LoadingButton from "@ui/LoadingButton";
import DialogWrapper from "@ui/DialogWrapper";
import useQuickJob from "@hooks/useQuickJob";
import FlowSelect from "@components/modals/quickTask/quickJob/FlowSelect";
import CustomerSelect from "@components/modals/quickTask/quickJob/CustomerSelect";
import ContractSelect from "@components/modals/quickTask/quickJob/ContractSelect";
import LocationSelect from "@components/modals/quickTask/quickJob/LocationSelect";
import DateUnitSelects from "@components/modals/quickTask/common/DateUnitSelects";
import NoteColorPicker from "@components/modals/quickTask/common/NoteColorPicker";
import LocationNoteTextField from "@components/modals/quickTask/quickJob/LocationNoteTextField";
import JobNoteTextField from "@components/modals/quickTask/quickJob/JobNoteTextField";
import PurchaseOrderNoTextField from "@components/modals/quickTask/quickJob/PurchaseOrderNoSmsAlertTextField";
import DropPointSelects from "@components/modals/quickTask/quickJob/DropPointSelects";
import DestinationSelect from "@components/modals/quickTask/quickJob/DestinationSelect";
import CustomerItemSelect from "@components/modals/quickTask/quickJob/CustomerItemSelect";
import useApiResponseHandler from "@hooks/useApiResponseHandler";
import ZoneSelect from "@components/modals/quickTask/quickJob/ZoneSelect";
import PaymentMethodSelect from "@components/modals/quickTask/quickJob/PaymentMethodSelect";

// External iports
import PropTypes from "prop-types";
import { map, includes, isEqual } from "lodash";
import { Backdrop, CircularProgress, Stack, Box, Button, Typography, Tooltip, Divider } from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AddTask, CheckCircleOutline, HelpOutline } from "@mui/icons-material";
import SmsAndEmailNotifications from "@components/modals/quickTask/quickJob/SmsAndEmailNotifications";

const validateForm = (current, handlers, errors) => {
  let errorsList = map(current, (state, key) => {
    const { flow, contract, location, destination } = current;

    // check if the field is required for the form to be valid
    const requiredField = key in errors;

    const isDropPointDeliveryOrMove = flow.data?.code?.toUpperCase()?.indexOf("LI") > -1 || flow.data?.code === "RE";
    const contractPaymentType = contract.data?.paymentTypeCode;
    const locationAreaIsOutOfArea = location.data?.areaIsOutOfArea && contractPaymentType === "CREDIT_CARD";
    const destinationAreaIsOutOfArea = destination.data?.areaIsOutOfArea && contractPaymentType === "CREDIT_CARD";

    if (requiredField) {
      let fieldValue = false;

      if (key === "notificationContacts") {
        const notificationContactsArray = Object.values(current.notificationContacts);

        notificationContactsArray.map((contact, index) => {
          const activeNotificationContacts = notificationContactsArray.filter((contact) => !contact.delete);

          if (!contact.delete) {
            const phoneNoLength = contact.smsOrEmail.replace(/\D/g, "").length;

            const smsIsValid = [10, 11].includes(phoneNoLength) && !contact.smsOrEmail.includes("@");

            const emailIsValid = contact.smsOrEmail.includes("@");

            const hasDuplicate =
              activeNotificationContacts.filter((c) => c.smsOrEmail === contact.smsOrEmail).length > 1;

            if ((!smsIsValid && !emailIsValid) || hasDuplicate) {
              if (activeNotificationContacts.length === 1 && contact.smsOrEmail === "") return;

              handlers.setSingleNestedCurrent(key, index, "error", true);

              fieldValue = true;
            }
          }
        });
      }

      // check if the required field has a value
      if (key === "locationAreaCustomFee" && locationAreaIsOutOfArea && !destinationAreaIsOutOfArea && !state) {
        fieldValue = true;
      }
      if (key === "destinationAreaCustomFee" && destinationAreaIsOutOfArea && !state) {
        fieldValue = true;
      }

      if (key !== "notificationContacts" && state?.value === null) {
        fieldValue = true;
      }

      // Only set drop point error when it's not deplacer/livrer and we don't have a value
      if (key === "dropPointPrimary") {
        fieldValue = flow.data && !isDropPointDeliveryOrMove && !state?.value;
      }

      // check if the selected flow is using a drop point or a destination
      const isError = key === "destination" && current.flow.data?.code !== "RE" ? !fieldValue : fieldValue;

      handlers.setSingleError([key], isError);

      return isError;
    }
  });
  // Check that the errorsList does not include a single true (error)
  const isFormValid = !includes(errorsList, true);

  return isFormValid;
};

const formatQuickJobPayload = (current) => {
  return {
    location_area_custom_fee: current.location.data?.areaIsOutOfArea ? current.locationAreaCustomFee : null,
    destination_area_custom_fee: current.destination.data?.areaIsOutOfArea ? current.destinationAreaCustomFee : null,
    job_template_id: current.flow?.value,
    customer_destination_location_id: current.destination?.value ?? "-1",
    customer_location_id: current.location?.value,
    note_comments: current.jobNote,
    note_comments_color: current.jobNoteColor,
    note_location: current.locationNote,
    purchase_order_no: current.purchaseOrderNo,
    repeat: current.repeat,
    start_date: current.date,
    supplier_location_id: current.dropPointPrimary.value,
    supplier_second_location_id: "-1",
    unit_id: current.unit.value ? current.unit.value : "-1",
    location_custom_area_code: current.locationCustomAreaCode ?? null,
    destination_custom_area_code: current.destinationCustomAreaCode ?? null,
    payment_method_id: current.paymentMethod?.id ?? null,
    notification_contacts: current.notificationContacts,
  };
};

const handleSubmitQuickJob = async (errors, current, handlers, createJob) => {
  const customerItemID = current.customerItem?.value;

  let isFormValid = validateForm(current, handlers, errors);

  if (isFormValid) {
    deleteExistingDefaultSmsOrEmailIfNeeded(current.notificationContacts[0]);
    try {
      const payload = formatQuickJobPayload(current);
      createJob.run(customerItemID, payload);
    } catch (error) {
      console.warn("[handleSubmitQuickJob] error:", error, current);
    }
  }
};

const deleteExistingDefaultSmsOrEmailIfNeeded = (defaultSmsOrEmail) => {
  const hasDefaultSmsOrEmail = defaultSmsOrEmail.id;
  const defaultValueIsEmpty = defaultSmsOrEmail.smsOrEmail === "";

  if (hasDefaultSmsOrEmail && defaultValueIsEmpty) {
    defaultSmsOrEmail.delete = true;
  }
};

const HeaderTopRight = ({ contractPaymentType }) => {
  if (!contractPaymentType) return null;

  return (
    <Stack>
      <Typography
        variant="overline"
        fontSize={10}
        lineHeight={1}
        textAlign="right"
      >
        Type de paiement
      </Typography>

      <Typography
        variant="h6"
        lineHeight={1.2}
      >
        {contractPaymentType ? (contractPaymentType === "CLIENT_ACCOUNT" ? "Compte Client" : "Carte de crédit") : "-"}
      </Typography>
    </Stack>
  );
};

const HeaderBottomRight = ({
  flow,
  location,
  contractPaymentType,
  locationAreaCustomFee,
  isLocationOutOfAreaError,
  locationCustomAreaCode,
  onChangeZone,
  onChangeLocationFee,
  paymentMethod,
  paymentMethods,
  onChangePaymentMethod,
}) => {
  return (
    <Stack spacing={1}>
      <PaymentMethodSelect
        isVisible={contractPaymentType === "CREDIT_CARD" && isArrayTruthy(paymentMethods)}
        paymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        onChangePaymentMethod={onChangePaymentMethod}
      />

      {flow?.code !== "RE" && (
        <ZoneSelect
          location={location}
          isLocationOutOfAreaError={isLocationOutOfAreaError}
          locationAreaCustomFee={locationAreaCustomFee}
          locationCustomAreaCode={locationCustomAreaCode}
          onChangeLocationFee={onChangeLocationFee}
          onChangeZone={onChangeZone}
        />
      )}
    </Stack>
  );
};

const handleRepeatButtons = (repeat, setSingleCurrent, action) => {
  if (action === "increment" && repeat < 25) {
    setSingleCurrent("repeat", repeat + 1);
  }

  if (action === "decrement" && repeat > 1) {
    setSingleCurrent("repeat", repeat - 1);
  }
};

const RepeatQuantity = ({ repeat, handlers }) => {
  return (
    <Stack
      direction="column"
      textAlign="center"
    >
      <Button
        size="small"
        sx={{ height: 5, minWidth: 0, width: 30 }}
        onClick={() => handleRepeatButtons(repeat, handlers.setSingleCurrent, "increment")}
      >
        <ArrowDropUpIcon />
      </Button>

      {repeat}

      <Button
        size="small"
        sx={{ height: 5, minWidth: 0, width: 30 }}
        onClick={() => handleRepeatButtons(repeat, handlers.setSingleCurrent, "decrement")}
      >
        <ArrowDropDownIcon />
      </Button>
    </Stack>
  );
};

const PriceBottomRight = ({ contract, location, customer, price }) => {
  if (
    !location.data ||
    !customer.data.stripeCustomerId ||
    !contract.data ||
    contract.data.paymentTypeCode !== "CREDIT_CARD"
  ) {
    return null;
  }

  return (
    <Stack>
      <Stack textAlign="right">
        <Stack
          direction="row"
          justifyContent="flex-end"
          minHeight="15px"
        >
          <Typography
            variant="overline"
            fontSize={10}
            lineHeight={1}
            textAlign="right"
            alignSelf="center"
          >
            TOTAL
          </Typography>

          <Tooltip
            title={
              <>
                <Typography
                  fontWeight="bold"
                  textAlign="center"
                >
                  Détails du prix
                </Typography>

                <Divider
                  variant="middle"
                  light
                  sx={{ backgroundColor: "lightgray" }}
                />
                <Stack
                  spacing="2px"
                  mt={1}
                >
                  {/* Backend sends us html inside of a string, so set it like so */}
                  <div dangerouslySetInnerHTML={{ __html: price?.html_details }}></div>

                  {/* <Stack direction="row">
                    <Typography color="inherit">Sous-total:</Typography>
                    <Typography color="inherit">46.28$</Typography>
                  </Stack>

                  <Stack direction="row">
                    <Typography color="inherit">TPS:</Typography>
                    <Typography color="inherit">1.24$</Typography>
                  </Stack>

                  <Stack direction="row">
                    <Typography color="inherit">TVQ:</Typography>
                    <Typography color="inherit">3.41$</Typography>
                  </Stack>

                  <Stack direction="row">
                    <Typography color="inherit">Total:</Typography>
                    <Typography color="inherit">53.95$</Typography>
                  </Stack> */}
                </Stack>
              </>
            }
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  minWidth: 245,
                  maxWidth: 350,
                },
              },
            }}
          >
            <HelpOutline
              sx={{ fontSize: 15, marginLeft: "2px", marginRight: "-2px" }}
              color="primary"
            />
          </Tooltip>
        </Stack>
        <Typography
          variant="h6"
          lineHeight={1.2}
        >
          {price?.total.toFixed(2)}$
        </Typography>
      </Stack>
    </Stack>
  );
};

function QuickJob({ isOpen, onClose, units, Header, taskType, setTaskType }) {
  const handleApiResponse = useApiResponseHandler();
  const { errors, data, current, stripe, handlers } = useQuickJob(units);
  const [paymentIntentStatus, setPaymentIntentStatus] = useState("En attente de la transaction...");
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);

  const handleChangePaymentMethod = useCallback((paymentMethod) => {
    handlers.setSingleCurrent("paymentMethod", paymentMethod);
  }, []);

  const handleChangeZone = useCallback((event) => {
    handlers.setSingleCurrent("locationCustomAreaCode", event.target.value);
  }, []);

  const handleChangeLocationFee = useCallback(
    ({ target }) => {
      const value = Number(target.value);

      if (value >= 0 && value <= 9999 && value !== current.locationAreaCustomFee) {
        handlers.setSingleCurrent("locationAreaCustomFee", value);
      }
    },
    [current.locationAreaCustomFee]
  );

  const lastCustomerItem = current.lastCustomerItemName
    ? `Dernier item à cette adresse: ${current.lastCustomerItemName}`
    : "";

  const contractPaymentType = current.contract.data?.paymentTypeCode;

  const isSelectedContractValid = useMemo(
    () => current.contract.data && current.contract.data.name.toLowerCase() !== "disposition",
    [current.contract.data]
  );

  const createJob = useAsync((customerItemID, payload) => {
    const price = current.price?.total;

    // If this function is executed, this means the validation passed so if the price total
    // is still 0, that means we allowed this and it's probably a flow 'vider-retirer' so
    // don't bother showing the backdrop and waiting for the webhook
    contractPaymentType === "CREDIT_CARD" && price !== 0 && setIsBackdropOpen(true);
    return API.Job.createJob(customerItemID, payload);
  });

  const headerProps = useMemo(
    () => ({
      taskType,
      setTaskType,
      topLeft: <Typography variant="h6">Ajouter une tâche</Typography>,
      topRight: <HeaderTopRight contractPaymentType={contractPaymentType} />,
      bottomRight: (
        <HeaderBottomRight
          flow={current.flow.data}
          location={current.location}
          contractPaymentType={contractPaymentType}
          locationAreaCustomFee={current.locationAreaCustomFee}
          isLocationOutOfAreaError={errors.locationAreaCustomFee}
          locationCustomAreaCode={current.locationCustomAreaCode}
          paymentMethod={current.paymentMethod}
          paymentMethods={stripe.paymentMethods}
          onChangePaymentMethod={handleChangePaymentMethod}
          onChangeZone={handleChangeZone}
          onChangeLocationFee={handleChangeLocationFee}
        />
      ),
    }),
    [
      taskType,
      stripe.paymentMethods,
      current.paymentMethod,
      current.flow,
      current.contract,
      current.location,
      current.locationAreaCustomFee,
      errors.locationAreaCustomFee,
      current.locationCustomAreaCode,
    ]
  );

  useEffect(() => {
    // When createJob.value is truthy, that means we received the json from the backend
    if (createJob.value) {
      const price = current.price?.total;

      if (createJob.value.status == "success") {
        if (contractPaymentType === "CREDIT_CARD") {
          // If the current price is 0, that means we didn't create a paymentIntent but we allowed
          // this to happen so don't bother showing the backdrop and waiting 1.5s before closing
          if (price === 0) return handleApiResponse(createJob.value, onClose);

          // Wait a bit before closing the backdrop so that the user can see the success message.
          setPaymentIntentStatus("Le paiement a réussi!");

          setTimeout(() => {
            setIsBackdropOpen(false);
            handleApiResponse(createJob.value, onClose);
          }, 1500);
        } else {
          // Handle api response right away when client account
          handleApiResponse(createJob.value, onClose);
        }
      } else {
        // Payment was not successfull, close the backdrop but not the modal
        contractPaymentType === "CREDIT_CARD" && price !== 0 && setIsBackdropOpen(false);
        handleApiResponse(createJob.value);
      }
    }
  }, [createJob.value]);

  return (
    <DialogWrapper
      onClose={onClose}
      open={isOpen}
    >
      <Stack position="relative">
        <Backdrop
          sx={{ color: "#fff", zIndex: 10, position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.3)" }}
          open={isBackdropOpen}
        >
          <Stack
            spacing={2}
            alignItems={"center"}
          >
            {/* Payment intent status */}
            <Typography
              align="center"
              variant="h6"
            >
              {paymentIntentStatus}
            </Typography>

            {/* Loading & success icons */}
            {!createJob.loading && createJob.value?.status == "success" ? (
              <CheckCircleOutline
                sx={{ fontSize: 50 }}
                color="success"
              />
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </Backdrop>
        <Header {...headerProps} />

        <Stack
          minHeight={500}
          px={3}
          pb={2}
          spacing={2}
        >
          {/* CUSTOMER SELECT */}
          <CustomerSelect
            isError={errors.customer}
            customer={current.customer}
            customers={data.customers}
            handlers={handlers}
          />

          {/* CONTRACT SELECT */}
          {current.customer.data && !current.customer.data.taskBlock && (
            <ContractSelect
              isError={errors.contract}
              contract={current.contract}
              contracts={data.contracts}
              customer={current.customer.data}
              handlers={handlers}
            />
          )}

          {/* LOCATION SELECT */}
          {isSelectedContractValid && (
            <LocationSelect
              customer={current.customer}
              contract={current.contract}
              isError={errors.location}
              location={current.location}
              locations={data.locations}
              handlers={handlers}
            />
          )}

          {/* CUSTOMER ITEM SELECT */}
          {isSelectedContractValid && (
            <CustomerItemSelect
              isError={errors.customerItem}
              contractPaymentType={contractPaymentType}
              customerItem={current.customerItem}
              customerItems={data.customerItems}
              handlers={handlers}
            />
          )}

          {/* FLOW SELECT */}
          {current.customerItem.data && current.customerItem.data.job_templates && (
            <FlowSelect
              isError={errors.flow}
              flow={current.flow}
              flows={current.customerItem.data.job_templates}
              handlers={handlers}
            />
          )}

          {/* DATE & UNIT SELECTS */}
          {current.customerItem.data && (
            <DateUnitSelects
              date={current.date}
              unit={current.unit}
              units={data.units}
              handlers={handlers}
            />
          )}

          {/* DROP POINTS SELECTS */}
          {current.customerItem.data && (!current.flow.data || current.flow.data.code !== "RE") && (
            <DropPointSelects
              flow={current.flow}
              isDropPointPrimaryError={errors.dropPointPrimary}
              dropPointPrimary={current.dropPointPrimary}
              dropPointSecondary={current.dropPointSecondary}
              suppliers={data.suppliers}
              handlers={handlers}
            />
          )}

          {/* DESTINATION SELECT */}
          {current.flow.data && current.flow.data.code === "RE" && (
            <DestinationSelect
              destinationCustomAreaCode={current.destinationCustomAreaCode}
              destinationAreaCustomFee={current.destinationAreaCustomFee}
              isDestinationOutOfAreaError={errors.destinationAreaCustomFee}
              isDestinationError={errors.destination}
              destination={current.destination}
              destinations={data.destinations}
              handlers={handlers}
            />
          )}

          {/* PURCHASE ORDER NO TEXTFIELD */}
          {current.customerItem.data && (
            <PurchaseOrderNoTextField
              isError={errors.smsAlertPhoneNumber}
              purchaseOrderNo={current.purchaseOrderNo}
              smsAlertPhoneNumber={current.smsAlertPhoneNumber}
              customerMobilePhoneNumber={current.customerMobilePhoneNumber}
              handlers={handlers}
            />
          )}

          {/* EMAIL AND SMS PHONE NUMBER TEXTFIELD */}
          {current.customerItem.data && (
            <Stack spacing={1}>
              <Typography variant="body1">Notifications SMS ou courriel</Typography>
              <SmsAndEmailNotifications
                notificationContacts={current.notificationContacts}
                customerMobilePhoneNumber={current.customerMobilePhoneNumber}
                addNotificationButtonDisabled={
                  current.notificationContacts[0].smsOrEmail === "" ||
                  Object.values(current.notificationContacts).filter((contact) => !contact.delete).length === 4
                }
                copyCellphoneNumberDisabled={
                  current.notificationContacts[0].smsOrEmail || !current.customerMobilePhoneNumber
                }
                setSingleCurrent={handlers.setSingleCurrent}
                setSingleNestedCurrent={handlers.setSingleNestedCurrent}
              />
            </Stack>
          )}

          {/* JOB NOTE TEXTFIELD */}
          {current.customerItem.data && (
            <JobNoteTextField
              jobNote={current.jobNote}
              handlers={handlers}
            />
          )}

          {/* LOCATION NOTE TEXTFIELD */}
          {current.customerItem.data && (
            <LocationNoteTextField
              locationNote={current.locationNote}
              handlers={handlers}
            />
          )}

          {/* NOTE COLOR PICKER */}
          {current.customerItem.data && (
            <NoteColorPicker
              colorStateName="jobNoteColor"
              noteColor={current.jobNoteColor}
              handlers={handlers}
            />
          )}

          {current.flow.data && (
            <PriceBottomRight
              contract={current.contract}
              location={current.location}
              customer={current.customer}
              price={current.price}
            />
          )}
        </Stack>

        <Stack
          direction="row"
          p={1}
          display="flex"
          justifyContent={current.location.data ? "space-between" : "flex-end"}
          spacing={1}
          height="64px"
          alignItems="center"
        >
          {/* LAST CUSTOMER ITEM */}
          <Typography variant="body2">{current.location.data && lastCustomerItem}</Typography>

          {/* BUTTONS */}
          <Stack
            direction="row"
            spacing={1}
            height="fit-content"
          >
            <Button
              onClick={onClose}
              disabled={createJob.loading}
            >
              Annuler
            </Button>

            <LoadingButton
              sx={{ padding: 1 }}
              disabled={stripe.isPaymentMethodInvalid || !isSelectedContractValid}
              loading={createJob.loading}
              startIcon={<AddTask />}
              onClick={() => handleSubmitQuickJob(errors, current, handlers, createJob)}
              loadingPosition="start"
            >
              Ajouter
            </LoadingButton>

            <Box
              width={30}
              height={"100%"}
            >
              {current.contract.data && contractPaymentType !== "CREDIT_CARD" && (
                <RepeatQuantity
                  repeat={current.repeat}
                  handlers={handlers}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { units: prevUnits, taskType: prevTaskType } = prevProps;
  const { units: nextUnits, taskType: nextTaskType } = nextProps;
  let arePropsEqual = true;

  if (!isEqual(prevUnits, nextUnits)) {
    arePropsEqual = false;
  }

  if (prevTaskType !== nextTaskType) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

QuickJob.propTypes = {
  onClose: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  Header: PropTypes.elementType.isRequired,
  taskType: PropTypes.string.isRequired,
  setTaskType: PropTypes.func.isRequired,
};

export default memo(QuickJob, areEqual);
