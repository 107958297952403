// React imports
import React, { memo, useCallback } from "react";

// External imports
import styled from "@emotion/styled";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sortBy, filter, size, isEqual } from "lodash";
import { IconButton, Icon, Badge } from "@mui/material";
import { Droppable, Draggable } from "react-beautiful-dnd";

// Local imports
import Tooltip from "@ui/Tooltip";
import JobCardMini from "@components/JobCardMini";
import { CollectionPath } from "@components/routing/constants";

const StyledUnitAssignment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  /* overflow-y: auto;  */ // This makes the row scrollable to the right
  flex-wrap: wrap; // This makes the row grow in size when there are too many assignments
  background: ${({ theme }) => theme.colors.palette.background.light};
  outline: ${(props) => (props.isDraggingOver ? "2px solid #1FA9D9" : "")};
  outline-offset: -2px;
  border-radius: ${(props) => (props.isDraggingOver ? "5px" : "")};
`;

const StyledUnitRow = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 0 0 2px 0;

  &:last-child {
    border-bottom: none;
  }
`;

const UnitRowHeader = styled.div`
  display: flex;
  padding: 2px;
  background: ${({ theme }) => theme.colors.components.dashboard.taskColumnBackground};
  align-items: center;
  justify-content: space-between;
  width: 150px;
  min-width: 150px;
`;

const CbprDraggableJobWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 6px;
  user-select: none;

  .route-mini-card {
    width: 200px;
  }
`;

const UnitRowHeaderTitle = styled.div`
  width: 100px;
`;

const areEqual = (prevProps, nextProps) => {
  const isEachAssignmentIdentical = isEqual(prevProps.unit, nextProps.unit);
  const isStatusEqual = prevProps.fetchDataStatus === nextProps.fetchDataStatus;

  // If the dailyAssignments or the fetchDatastatus did not change, don't re-render por favor.
  return isEachAssignmentIdentical && isStatusEqual;
};

const renderUnpublished = (unpublishedCount, unitId) => {
  const icon = <Icon color="secondary">tap_and_play</Icon>;

  if (!unpublishedCount) {
    return icon;
  }

  return (
    <Badge
      id={`cpbr-unpublished-unit-${unitId}`}
      badgeContent={unpublishedCount}
      color="secondary"
    >
      {icon}
    </Badge>
  );
};

const getUnpublishedAssignmentsCount = (dailyAssignments) =>
  size(filter(dailyAssignments, (assignment) => !assignment.published));

const getPublishedAssignmentsCount = (dailyAssignments) =>
  size(filter(dailyAssignments, (assignment) => assignment.published));

const sortAssignmentsByDisplayOrder = (dailyAssignments) => sortBy(dailyAssignments, (item) => item.displayOrder);

const handleOnClick = (job, type, navigate) => {
  let path = "";
  console.log(type);
  if (type === "route" || type === "job") {
    path = `/customers/${job.customerId}/contracts/${job.contractId}/preparations/${job.customerItemId}/${type}s/${job.id}`;
  }

  if (type === "collection") {
    path = CollectionPath({ collectionId: job.geoCollectionUuid });
  }

  navigate(path);
};

function UnitRow({
  unit,
  prefix,
  fetchDataStatus,
  currentDate,
  handlePublishJob,
  handleOpenUnitSelector,
  handleOpenRemoveHelpOnUnit,
}) {
  const { id, name, dailyAssignments } = unit;
  const navigate = useNavigate();

  const today = new Date().toISOString().slice(0, 10);
  const isDragAndDropDisabled = fetchDataStatus || currentDate < today;

  const publishedCount = useCallback(getPublishedAssignmentsCount(dailyAssignments), [dailyAssignments]);
  const unpublishedCount = useCallback(getUnpublishedAssignmentsCount(dailyAssignments), [dailyAssignments]);
  const sortedAssignments = useCallback(sortAssignmentsByDisplayOrder(dailyAssignments), [dailyAssignments]);

  return (
    <StyledUnitRow>
      {/* The unit name on the left side of the row */}
      <UnitRowHeader
        id={`cpbr-unit${id}`}
        className="unit-row-header"
      >
        <UnitRowHeaderTitle>
          {name} ({publishedCount})
        </UnitRowHeaderTitle>
        <Tooltip title={<Trans i18nKey="dispatch" />}>
          <IconButton onClick={() => handlePublishJob(id, unpublishedCount)}>
            {renderUnpublished(unpublishedCount, id)}
          </IconButton>
        </Tooltip>
      </UnitRowHeader>

      {/* The unit assignments row with each AssignmentCard inside */}
      <Droppable
        droppableId={`${prefix}`}
        direction="horizontal"
        isDropDisabled={isDragAndDropDisabled}
      >
        {(provided, snapshot) => (
          <StyledUnitAssignment
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {sortedAssignments.length > 0 &&
              sortedAssignments.map((assignment, index) => {
                const { id, job, type, unitId, helpRequest, helpOnUnit, published, displayOrder } = assignment;

                return (
                  <Draggable
                    key={`${id}`}
                    draggableId={`${id}`}
                    index={index}
                    isDragDisabled={isDragAndDropDisabled}
                  >
                    {(provided) => {
                      return (
                        <CbprDraggableJobWrapper>
                          <div
                            key={id}
                            className={type === "job" ? "job-mini-card" : "route-mini-card"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <JobCardMini
                              className={type === "job" ? "job-mini-card" : "route-mini-card"}
                              openUnitSelector={() => handleOpenUnitSelector(unitId, id)}
                              openRemoveHelpOnUnit={handleOpenRemoveHelpOnUnit}
                              unitSelectorModal
                              job={job}
                              unitId={unitId}
                              helpRequest={helpRequest}
                              helpOnUnit={helpOnUnit}
                              published={published}
                              onClick={() => handleOnClick(job, type, navigate)}
                            />
                          </div>
                        </CbprDraggableJobWrapper>
                      );
                    }}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </StyledUnitAssignment>
        )}
      </Droppable>
    </StyledUnitRow>
  );
}

export default memo(UnitRow, areEqual);
