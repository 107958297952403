// React imports
import React, { ReactElement } from "react";
// Externals imports
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent } from "@mui/material";
import { UnitBase } from "@components/modals/quickTask/quickPep/QuickPepLayout";

type QuickPepUnitsDropdownProps = {
  value: string;
  error: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  units: UnitBase[];
};

const QuickPepUnitsDropdown = ({ value, error, onChange, units }: QuickPepUnitsDropdownProps): ReactElement => {
  const renderMenuItems = (options: UnitBase[]): ReactElement | ReactElement[] => {
    if (!options) {
      return <Dropdown.MenuItem value=""></Dropdown.MenuItem>;
    }
    return options.map((option: UnitBase) => (
      <Dropdown.MenuItem
        key={option.value}
        value={option.value?.toString()}
      >
        {option.label}
      </Dropdown.MenuItem>
    ));
  };
  return (
    <Dropdown
      disabled={!units.length}
      value={value}
      name={"unit"}
      onChange={onChange}
      error={error}
      label="Camion (facultatif)"
      width="275px"
    >
      {renderMenuItems(units)}
    </Dropdown>
  );
};

export default QuickPepUnitsDropdown;
