import { MutationResult } from "@@types/apiResponse";
import { axiosClientV1 as http } from "../axios";

import { LineaCollectionPayload } from "@components/modals/quickTask/quickPep/hooks/quick-pep-layout.hook";

const createLineaCollection = async (payload: LineaCollectionPayload): Promise<MutationResult<void>> => {
  try {
    const res = await http.post<MutationResult<void>>(`/collections`, { collection: payload });
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default createLineaCollection;
