// Internal imports
import "regenerator-runtime/runtime";
import React from "react";

// External imports
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import "moment/dist/locale/fr-ca";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Local imports
import Router from "@components/routing/Router";
import theme from "@styles/theme";
import muiTheme from "@styles/mui-theme";
import AuthProvider from "@context/auth/AuthProvider";
import { ListCodesProvider } from "@context/list-codes/ListCodesContext";
import { AdministrativeSectorsProvider } from "@context/geo/AdministrativeSectorsContext";

const locale = "fr-ca";

const queryClient = new QueryClient();

const GlobalStyle = css`
  html {
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.mainFont};
`;

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Global styles={GlobalStyle} />
        <EmotionThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={10000}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={locale}
            >
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="top-right"
                />
                <AuthProvider>
                  <ListCodesProvider>
                    <AdministrativeSectorsProvider>
                      <Wrapper>
                        <CssBaseline />
                        <Router />
                      </Wrapper>
                    </AdministrativeSectorsProvider>
                  </ListCodesProvider>
                </AuthProvider>
              </QueryClientProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </EmotionThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
