// React imports
import React, { ReactElement } from "react";
// Externals imports
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent } from "@mui/material";
import { LineaCollectionSector } from "@@types/collection-sectors/collection-sector.type";

type QuickPepCollectionSectorsDropdownProps = {
  value: string;
  error: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  collectionSectors: LineaCollectionSector[];
};

const QuickPepCollectionSectorsDropdown = ({
  value,
  error,
  onChange,
  collectionSectors,
}: QuickPepCollectionSectorsDropdownProps): ReactElement => {
  const renderMenuItems = (options: LineaCollectionSector[]): ReactElement | ReactElement[] => {
    if (!options) {
      return <Dropdown.MenuItem value=""></Dropdown.MenuItem>;
    }
    return options.map((option: LineaCollectionSector) => (
      <Dropdown.MenuItem
        key={option.id}
        value={option.id.toString()}
      >
        {option.collectionSectorCompleteName}
      </Dropdown.MenuItem>
    ));
  };
  return (
    <Dropdown
      disabled={!collectionSectors.length}
      value={value || ""}
      name={"collectionSector"}
      onChange={onChange}
      error={error}
      label="Secteur de collecte"
      fullWidth
    >
      {renderMenuItems(collectionSectors)}
    </Dropdown>
  );
};

export default QuickPepCollectionSectorsDropdown;
