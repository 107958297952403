import { QueryKey } from "@tanstack/react-query";

type CollectionSectorKeys = {
  base: "collection-sectors";
  streets: (collectionSectorId: string) => QueryKey;
  all: () => QueryKey;
  byContract: (contractId: string) => QueryKey;
};

const collectionSectorsKeys: CollectionSectorKeys = {
  base: "collection-sectors",
  streets: (collectionSectorId: string) => [collectionSectorsKeys.base, "streets", { collectionSectorId }],
  all: () => ["collection-sectors"],
  byContract: (contractId: string) => [collectionSectorsKeys.base, "by-contract", contractId],
};

export default collectionSectorsKeys;
