import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { Customer } from "@@types/customers/customer.type";

const fetchPepCustomersList = async (): Promise<ApiResponse<Customer[]>> => {
  try {
    const res = await http.get<ApiResponse<Customer[]>>(`/customers/pep_customers_list`);
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchPepCustomersList;
