import React, { ReactElement } from "react";
import { IconProps } from "@components/icons/Icon.type";
import { defaultIconProps } from "@components/icons/constants";
import Container from "@components/icons/Container";

export type LineIconProps = {
  // Add Props
};

const LineIcon = ({
  size = defaultIconProps.size,
  fillColor = defaultIconProps.fillColor,
}: IconProps = defaultIconProps): ReactElement => {
  return (
    <Container size={size}>
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M8 1.5C8 2.05228 8.44772 2.5 9 2.5C9.55228 2.5 10 2.05228 10 1.5C10 0.947715 9.55228 0.5 9 0.5C8.44772 0.5 8 0.947715 8 1.5ZM8 1.5C6.4087 1.5 4.88258 2.13214 3.75736 3.25736C2.63214 4.38258 2 5.9087 2 7.5M2 7.5C1.44772 7.5 1 7.94772 1 8.5C1 9.05228 1.44772 9.5 2 9.5C2.55228 9.5 3 9.05228 3 8.5C3 7.94772 2.55228 7.5 2 7.5Z"
          stroke="#828282"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default LineIcon;
