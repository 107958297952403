import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV1 as http } from "../axios";
import { LineaCollectionSector } from "@@types/collection-sectors/collection-sector.type";

const fetchLineaCollectionSectorsByContract = async (
  contractId: string
): Promise<ApiResponse<LineaCollectionSector[]>> => {
  try {
    const res = await http.get<ApiResponse<LineaCollectionSector[]>>(
      `/collection_sectors/by_contract?contract_id=${contractId}`
    );
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchLineaCollectionSectorsByContract;
