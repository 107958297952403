import { useQuery, UseQueryResult } from "@tanstack/react-query";
import customer from "@hooks/customers/customer.keys";
import { Customer } from "@@types/customers/customer.type";
import fetchPepCustomersList from "../../services/customers/fetch-pep-customers-list.service";
import { mapResponsesToCustomers } from "@@types/customers/mappers/mapResponseToCustomer.mapper";

const useFetchPepCustomersList = (): UseQueryResult<Customer[]> => {
  return useQuery({
    queryKey: customer.all(),
    queryFn: () => fetchPepCustomersList(),
    select: ({ data: response }): Customer[] => mapResponsesToCustomers(response || []),
  });
};

export default useFetchPepCustomersList;
