import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationResult } from "@@types/apiResponse";
import { DefaultError } from "@tanstack/react-query/build/modern/index";
import { LineaCollectionPayload } from "@components/modals/quickTask/quickPep/hooks/quick-pep-layout.hook";
import collectionSectorsKeys from "@hooks/collection-sectors/collection-sectors.keys";
import createLineaCollection from "../../services/collection-sectors/create-linea-collection.service";

export type UseCreateLineaCollection = {
  mutate: (payload: LineaCollectionPayload) => Promise<MutationResult<void>>;
  isPending: boolean;
};

function useCreateLineaCollection(): UseCreateLineaCollection {
  const queryClient = useQueryClient();

  const mutation = useMutation<MutationResult<void>, DefaultError, LineaCollectionPayload>({
    mutationFn: (payload: LineaCollectionPayload): Promise<MutationResult<void>> => createLineaCollection(payload),
    onSuccess: async (_data) => {
      // Invalidate queries to reflect the changes
      await queryClient.invalidateQueries({
        queryKey: collectionSectorsKeys.all(),
        exact: false,
      });
    },
  });

  const mutate = async (payload: LineaCollectionPayload): Promise<MutationResult<void>> => {
    const response = await mutation.mutateAsync(payload);
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateLineaCollection;
