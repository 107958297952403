import React, { ReactElement } from "react";
import { MenuItem } from "@mui/material";
import Dropdown from "@ui/dropdown/Dropdown";

export type ModalAddMasterRouteTemplateMatterProps = {
  matter: string;
};

const ModalAddMasterRouteTemplateMatter = ({ matter }: ModalAddMasterRouteTemplateMatterProps): ReactElement => {
  return (
    <Dropdown
      disabled
      label="Matière"
      value={matter}
      name="matter"
      width="100px"
    >
      <MenuItem value={matter}>{matter}</MenuItem>
    </Dropdown>
  );
};

export default ModalAddMasterRouteTemplateMatter;
