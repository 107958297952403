// React imports
import React from "react";

// External imports
import { isEqual } from "lodash";
import styled from "@emotion/styled";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Droppable, Draggable } from "react-beautiful-dnd";

// Local imports
import JobCardMini from "@components/JobCardMini";
import { CollectionPath } from "@components/routing/constants";

const UnitRowHeader = styled.div`
  display: flex;
  padding: 2px;
  background: ${({ theme }) => theme.colors.components.dashboard.taskColumnBackground};
  align-items: center;
  justify-content: space-between;
  width: 150px;
  position: relative;
`;

const StyledUnitAssignment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.palette.background.light};
  outline: ${(props) => (props.isDraggingOver ? "2px solid #1FA9D9" : "")};
  outline-offset: -2px;
  border-radius: ${(props) => (props.isDraggingOver ? "5px" : "")};
`;

const CbprDraggableJobWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 6px;
  user-select: none;

  .job-mini-card {
    /* width: 100px; */
  }

  .route-mini-card {
    width: 200px;
  }
`;

const UnitRowHeaderTitle = styled.div`
  width: 100px;
`;

const areEqual = (prevProps, nextProps) => {
  const isEachAssignmentIdentical = isEqual(prevProps.unassignedAssignments, nextProps.unassignedAssignments);
  const isStatusEqual = prevProps.fetchDataStatus === nextProps.fetchDataStatus;

  // If the unassignedAssignments or the fetchDatastatus did not change, don't re-render por favor.
  return isEachAssignmentIdentical && isStatusEqual;
};

function UnassignedCart({ currentDate, fetchDataStatus, unassignedAssignments }) {
  const navigate = useNavigate();
  const today = new Date().toISOString().slice(0, 10);
  const isDragAndDropDisabled = fetchDataStatus || currentDate < today;

  const handleOnClick = (job, type) => {
    let path = "";

    if (type === "route" || type === "job") {
      path = `/customers/${job.customerId}/contracts/${job.contractId}/preparations/${job.customerItemId}/${type}s/${job.id}`;
    }

    if (type === "collection") {
      path = CollectionPath({ collectionId: job.geoCollectionUuid });
    }

    navigate(path);
  };

  return (
    <div
      style={{
        display: "flex",
        position: "sticky",
        top: 0,
        flexDirection: "row",
        backgroundColor: "white",
        marginBottom: 2,
      }}
    >
      <UnitRowHeader
        id="cpbr-unassigned"
        className="unit-row-header"
      >
        <UnitRowHeaderTitle>
          <Trans i18nKey="tasks" /> ({unassignedAssignments.length})
        </UnitRowHeaderTitle>
      </UnitRowHeader>

      <Droppable
        droppableId="-1"
        direction="horizontal"
        isDropDisabled={isDragAndDropDisabled}
      >
        {(provided, snapshot) => (
          <StyledUnitAssignment
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {unassignedAssignments.length > 0 &&
              unassignedAssignments.map((assignment, index) => {
                const { id, job, type, unitId, helpRequest, helpOnUnit, published } = assignment;

                return (
                  <Draggable
                    key={`${id}`}
                    draggableId={`${id}`}
                    index={index}
                    isDragDisabled={isDragAndDropDisabled}
                  >
                    {(provided) => {
                      return (
                        <CbprDraggableJobWrapper>
                          <div
                            key={id}
                            className={type === "job" ? "job-mini-card" : "route-mini-card"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <JobCardMini
                              unitSelectorModal
                              job={job}
                              unitId={unitId}
                              helpRequest={helpRequest}
                              helpOnUnit={helpOnUnit}
                              published={published}
                              onClick={() => handleOnClick(job, type)}
                            />
                          </div>
                        </CbprDraggableJobWrapper>
                      );
                    }}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </StyledUnitAssignment>
        )}
      </Droppable>
    </div>
  );
}

export default React.memo(UnassignedCart, areEqual);
