import { ROUTES } from "@components/routing/RouterV2";

export const ROUTES_PARAMETERS = {
  administrativeSectorId: ":administrativeSectorId",
  collectionId: ":collectionId",
  locationId: ":locationId",
  masterCollectionSectorId: ":masterCollectionSectorId",
  matterCode: ":matterCode",
  routeId: ":routeId",
  sectorDetailId: ":sectorDetailId",
  sectorId: ":sectorId",
};

export const LOCATIONS_SUB_ROUTES = {
  location: `/${ROUTES_PARAMETERS.locationId}`,
};

export const LOCATIONS_ROUTES = {
  location: `${ROUTES.locations}/${ROUTES_PARAMETERS.locationId}`,
};

export const ROUTE_ROUTES = {
  route: `${ROUTES.routes}/${ROUTES_PARAMETERS.routeId}`,
};

export const ROUTE_SUB_ROUTES = {
  route: `/${ROUTES_PARAMETERS.routeId}`,
};

export const PEP_ROUTES = {
  administrativeSectors: `/secteurs-administratifs`,
  collections: `/collectes`,
  masterCollectionSectors: `/secteurs-collecte-maitres`,
  sectorDetails: `/details-secteurs`,
  sectorDetailsMasterCollectionSectors: `/details-secteurs/${ROUTES_PARAMETERS.matterCode}/secteurs-collecte-maitres`,
  sectors: `/secteurs`,
};

type MasterCollectionSectorPathIds = {
  masterCollectionSectorId: string;
};

type CollectionPathIds = {
  collectionId: string;
};

export const MasterCollectionSectorPath = ({ masterCollectionSectorId }: MasterCollectionSectorPathIds): string => {
  const path = `${ROUTES.pep}${PEP_ROUTES.masterCollectionSectors}/${ROUTES_PARAMETERS.masterCollectionSectorId}`;
  return path.replace(ROUTES_PARAMETERS.masterCollectionSectorId, masterCollectionSectorId);
};

export const CollectionPath = ({ collectionId }: CollectionPathIds): string => {
  const path = `${ROUTES.pep}${PEP_ROUTES.collections}/${ROUTES_PARAMETERS.collectionId}`;
  return path.replace(ROUTES_PARAMETERS.collectionId, collectionId);
};
