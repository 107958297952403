// React imports
import React, { ReactElement } from "react";
// Externals imports
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent } from "@mui/material";
import { CustomerContract } from "@@types/customers/customer.type";

type QuickPepContractsDropdownProps = {
  value: string;
  error: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  contracts: CustomerContract[];
};

const QuickPepContractsDropdown = ({
  value,
  error,
  onChange,
  contracts,
}: QuickPepContractsDropdownProps): ReactElement => {
  const renderMenuItems = (options: CustomerContract[]): ReactElement | ReactElement[] => {
    if (!options) {
      return <Dropdown.MenuItem value=""></Dropdown.MenuItem>;
    }
    return options.map((option: CustomerContract) => (
      <Dropdown.MenuItem
        key={option.id}
        value={option.id.toString()}
      >
        {option.name}
      </Dropdown.MenuItem>
    ));
  };
  return (
    <Dropdown
      disabled={!contracts.length}
      value={value || ""}
      name={"contract"}
      onChange={onChange}
      error={error}
      label="Contrat"
      fullWidth
    >
      {renderMenuItems(contracts)}
    </Dropdown>
  );
};

export default QuickPepContractsDropdown;
