import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { LineaCollectionSector } from "@@types/collection-sectors/collection-sector.type";
import collectionSectorsKeys from "@hooks/collection-sectors/collection-sectors.keys";
import { mapResponsesToCollectionSectors } from "@@types/collection-sectors/mappers/mapResponseToCollectionSectorBase.mapper";
import fetchLineaCollectionSectorsByContract from "../../services/collection-sectors/fetch-linea-collector-sectors-list.service";

const useFetchLineaCollectionSectorsByContract = (contractId: string): UseQueryResult<LineaCollectionSector[]> => {
  return useQuery({
    queryKey: collectionSectorsKeys.byContract(contractId),
    queryFn: () => fetchLineaCollectionSectorsByContract(contractId),
    select: ({ data: response }): LineaCollectionSector[] => mapResponsesToCollectionSectors(response || []),
    enabled: contractId !== "",
  });
};

export default useFetchLineaCollectionSectorsByContract;
