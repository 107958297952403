import React, { ReactElement } from "react";
import { MenuItem } from "@mui/material";
import Dropdown from "@ui/dropdown/Dropdown";

export type ModalAddMasterRouteTemplateAreaProps = {
  area: string;
};

const ModalAddMasterRouteTemplateArea = ({ area }: ModalAddMasterRouteTemplateAreaProps): ReactElement => {
  return (
    <Dropdown
      disabled
      label="Arr."
      value={area.toUpperCase()}
      name="area"
      width="140px"
    >
      <MenuItem value={area.toUpperCase()}>{area.toUpperCase()}</MenuItem>
    </Dropdown>
  );
};

export default ModalAddMasterRouteTemplateArea;
