import { useQuery, UseQueryResult } from "@tanstack/react-query";
import customer from "@hooks/customers/customer.keys";
import { Customer } from "@@types/customers/customer.type";
import fetchShowCustomer from "../../services/customers/fetch-customer.service";
import { mapResponseToCustomer } from "@@types/customers/mappers/mapResponseToCustomer.mapper";
import { CustomerResponse } from "@@types/customers/customer-response.type";

const useFetchShowCustomer = (customerId: string): UseQueryResult<Customer | undefined> => {
  const respondTo = (response: CustomerResponse | undefined): Customer | undefined => {
    if (!response) return undefined;

    return mapResponseToCustomer(response);
  };

  return useQuery({
    queryKey: customer.detail(customerId),
    queryFn: () => fetchShowCustomer(customerId),
    select: ({ data: response }): Customer | undefined => respondTo(response),
  });
};

export default useFetchShowCustomer;
