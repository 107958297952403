// React imports
import React, { ReactElement } from "react";
// Externals imports
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent } from "@mui/material";
import { Customer } from "@@types/customers/customer.type";

type QuickPepCustomersDropdownProps = {
  value: string;
  error: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
  customers: Customer[];
};

const QuickPepCustomersDropdown = ({
  value,
  error,
  onChange,
  customers,
}: QuickPepCustomersDropdownProps): ReactElement => {
  const renderMenuItems = (options: Customer[]): ReactElement | ReactElement[] => {
    if (!options) {
      return <Dropdown.MenuItem value=""></Dropdown.MenuItem>;
    }
    return options.map((option: Customer) => (
      <Dropdown.MenuItem
        key={option.id}
        value={option.id.toString()}
      >
        {option.name}
      </Dropdown.MenuItem>
    ));
  };
  return (
    <Dropdown
      disabled={!customers.length}
      value={value || ""}
      name={"customer"}
      onChange={onChange}
      error={error}
      label="Client"
      fullWidth
    >
      {renderMenuItems(customers)}
    </Dropdown>
  );
};

export default QuickPepCustomersDropdown;
