import { Stack, TextField } from "@mui/material";
import React, { ReactElement } from "react";
// Externals imports
import moment from "moment/moment";
import { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers/";

type QuickPepDateSelectorProps = {
  value: string;
  onChange: (event: Moment | null) => void;
};

const QuickPepDateSelector = ({ value, onChange }: QuickPepDateSelectorProps): ReactElement => {
  return (
    <Stack width="275px">
      <DatePicker
        label="Date de début"
        value={value ? moment(value) : moment()}
        onChange={(event) => onChange(event)}
        renderInput={(params) => <TextField {...params} />}
      />
    </Stack>
  );
};

export default QuickPepDateSelector;
