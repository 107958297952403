import { CustomerResponse } from "@@types/customers/customer-response.type";
import { Customer } from "@@types/customers/customer.type";

function mapResponseToCustomer(response: CustomerResponse): Customer {
  return {
    id: response.id,
    name: response.name,
    contracts: response.contracts,
    isFromCiWeb: response.isFromCiWeb,
  };
}

function mapResponsesToCustomers(responses: CustomerResponse[]): Customer[] {
  return responses.map(mapResponseToCustomer);
}

export { mapResponseToCustomer, mapResponsesToCustomers };
