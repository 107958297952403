import CustomersPage from "@pages/CustomersPage";
import React from "react";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";

import Customer from "@components/Customer";
import Contract from "@components/Contract";
import CustomerItem from "@components/CustomerItem";
import LoadingScreen from "@components/LoadingScreen";
import Unit from "@components/Unit";
import UnitsList from "@components/units/UnitsList";

// import Location from "@components/location/Location";
import Login from "@components/login/Login";
import AuthRoute from "@components/routing/AuthRoute";
import PrivateRoute from "@components/routing/PrivateRoute";
import CustomerContextProvider from "@context/customer/CustomerContextProvider";
import JobPage from "@pages/JobPage";
import SalesPage from "@pages/SalesPage";
import AssignmentDashboardPage from "@pages/AssignmentDashboardPage";
import UserPage from "@pages/UsersPage";
import ItemsPage from "@pages/ItemsPage";
import SubRouteManagementPage from "@pages/SubRouteManagementPage";
import RouteManagementPage from "@pages/RouteManagementPage.tsx";
import LocationPage from "@pages/LocationPage";

import * as Sentry from "@sentry/react";
import RollOffLiftingRequestsPage from "@pages/RollOffLiftingRequestsPage";
import PricesAndTaxesListPage from "@pages/PricesAndTaxesListPage";
import RouterV2 from "@components/routing/RouterV2";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const ROUTER = sentryCreateBrowserRouter([
  { element: <AuthRoute />, children: [{ path: "/login", element: <Login /> }] },
  {
    element: <PrivateRoute />,
    children: [
      { path: "*", element: <RouterV2 /> },
      {
        path: "/",
        element: <Outlet />,
        children: [
          { index: true, element: <AssignmentDashboardPage /> },
          {
            path: "customers",
            children: [
              {
                index: true,
                element: (
                  <CustomerContextProvider>
                    <CustomersPage />
                  </CustomerContextProvider>
                ),
              },
              {
                path: ":customerId",
                children: [
                  { index: true, element: <Customer /> },
                  {
                    path: "contracts/:contractId",
                    children: [
                      {
                        index: true,
                        element: <Contract />,
                      },
                      {
                        path: "locations/:locationId/:action?",
                        element: <LocationPage />,
                      },
                      {
                        path: "preparations/:customerItemId",
                        children: [
                          { index: true, element: <CustomerItem /> },
                          {
                            path: "jobs/:jobId",
                            element: <JobPage />,
                          },
                          {
                            path: "routes/:routeId",
                            element: <RouteManagementPage />,
                          },
                          {
                            path: "route_template_locations/*",
                            element: <SubRouteManagementPage />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          { path: "items", element: <ItemsPage /> },
          { path: "users", element: <UserPage /> },
          { path: "jobs/:jobId", element: <JobPage /> },
          { path: "prices", element: <PricesAndTaxesListPage /> },
          { path: "transactions", element: <SalesPage /> },
          {
            path: "units",
            children: [
              { index: true, element: <UnitsList /> },
              { path: ":unitId", element: <Unit /> },
            ],
          },
          { path: "roll-off-lifting-requests", element: <RollOffLiftingRequestsPage /> },
        ],
      },
    ],
  },
]);

const Router = () => {
  return (
    <LoadingScreen>
      <RouterProvider router={ROUTER} />
    </LoadingScreen>
  );
};

export default Router;
